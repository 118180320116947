import { CustomerStateWireFormat } from '@clark-customer/entities';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { MandateStep } from '@clarksource/client/services/mandate-state';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class MandateStatus extends ClarkRoute {
  @service declare experiments: Services['experiments'];
  @service declare features: Services['features'];
  @service declare mandateState: Services['mandate-state'];
  @service declare router: Services['router'];
  @service declare session: Services['session'];

  get isNewOnboardingFlow(): boolean {
    if (!this.features.isEnabled('CUSTOMER_INTENT')) {
      return false;
    }

    return (
      this.customerIntentVersion === 'v1' || this.customerIntentVersion === 'v2'
    );
  }

  get customerIntentVersion(): string {
    return this.experiments.getVariant('2021Q3CustomerOnboardingIntent');
  }

  get isAlreadyPartOfIncentiveInfoMandateFunnelExperiment(): boolean {
    return (
      this.experiments.hasVariant('2024Q3IncentiveInfoMandateFunnel') &&
      this.experiments.getVariant('2024Q3IncentiveInfoMandateFunnel') === 'v1'
    );
  }

  disableRestrictedExperiments(): void {
    if (this.mandateState.isNetworkOrPartnerExcludedForOpenJourneys) {
      this.experiments.forceVariant('2021Q3OpenBuyingJourney', 'control');
      this.experiments.forceVariant(
        '2021Q3CustomerOnboardingIntent',
        'control',
      );
    }
  }

  async model() {
    return {
      user: this.modelFor('index'),
    };
  }

  async beforeModel() {
    // since this is the beginning of the mandate funnel, disable restricted funnel
    // experiments here
    this.disableRestrictedExperiments();
    const user = this.session.currentUser;

    // go to any missing step of the mandate funnel before proceeding.
    let missingStepExists = false;
    if (user) {
      missingStepExists = this.mandateState.gotoMissingStep(
        user,
        MandateStep.Status,
      );
    }

    if (missingStepExists) {
      return;
    }

    if (!this.mandateState.isStepPartOfFunnel(MandateStep.Status)) {
      this.mandateState.completeAndNavigateToNextStep(MandateStep.Status);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const customerState = user?.mandate?.customer_state;

    /**
     * FIXME:: prevent clark 2 Self Service
     * users from visiting mandate status page.
     * (seems android native app redirects users to /mandate route on back press
     * src/main/java/de/webview/ClarkWebView.kt:goBack)
     * This could be in client/app/components/mandate/mandate-index/component.ts
     * but that's undesirable and this whole redirection from mandate/status
     * route needs refactoring
     */

    if (customerState === CustomerStateWireFormat.SelfService) {
      this.router.replaceWith('contracts.index');
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const isIntentSpecified = Boolean(user?.mandate?.customer_intent);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const isPhoneVerified = Boolean(user?.mandate?.primary_phone_verified);
    const isCodelessCampaignUser =
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      Boolean(user?.mandate?.codeless_campaign) &&
      this.isAlreadyPartOfIncentiveInfoMandateFunnelExperiment;

    if (isIntentSpecified || isPhoneVerified || isCodelessCampaignUser) {
      return;
    }

    if (this.isNewOnboardingFlow) {
      this.router.replaceWith('customer.onboarding.intention');
    }
  }

  @action
  navigateTo(route: string) {
    this.router.transitionTo(route);
  }

  @action
  didTransition() {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    super.actions.didTransition.call(this, false);
  }
}
