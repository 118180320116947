/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomerState } from '@clark-customer/entities';
import Inquiry from '@clark-home/ui/models/inquiry';
import Opportunity from '@clark-home/ui/models/opportunity';
import Product from '@clark-home/ui/models/product';
import Recommendation from '@clark-home/ui/models/recommendation';
import { makeAppointmentsResource } from '@clark-home/ui/resources/appointments';
import { makeContractPlaceholdersResource } from '@clark-home/ui/resources/contract-placeholders';
import { makeMissedAppointmentsResource } from '@clark-home/ui/resources/missed-appointments';
import { makePausedOpportunitiesResource } from '@clark-home/ui/resources/paused-opportunities';
import { emptyState } from '@clark-home/ui/utils/home-screen/user';
import { flattenInquiries } from '@clark-home/ui/utils/models/inquiry';
import type {
  CockpitData,
  Optimization,
  Product as ProductType,
} from '@clark-utils/enums-and-types';
import { ErrorCode, ProductSoldBy } from '@clark-utils/enums-and-types';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import * as Sentry from '@sentry/browser';
import { dropTask } from 'ember-concurrency';
import { runTask } from 'ember-lifeline';

const Model = {
  inquiry: Inquiry,
  opportunity: Opportunity,
  product: Product,
  recommendation: Recommendation,
} as const;

type ModelName = keyof typeof Model;

type AvailableModel = (typeof Model)[ModelName];

type ParamsForDeserializeCollection<T extends AvailableModel> = {
  additionalProperties?: {
    [key: string]: any;
  };
  collection: T[];
  modelName: ModelName;
};

type ContractsData = {
  contracts: Product[] | null;
  inquiries: any[] | null;
};

export default class IndexManagerLandingContractsRoute extends ClarkRoute {
  @service declare api: Services['api'];
  @service declare customer: Services['customer'];
  @service declare experiments: Services['experiments'];
  @service declare localStorage: Services['local-storage'];
  @service declare router: Services['router'];
  @service declare session: Services['session'];
  @service declare query: Services['query'];

  @service('demandcheck/user')
  declare demandcheckUser: Services['demandcheck/user'];

  queryParams = {
    offerExpired: {},
    openMessengerOnLoad: { replace: true, refreshModel: false },
    openNotificationCentreOnLoad: { replace: true, refreshModel: false },
    scrollToContract: {},
    scrollToSection: {},
    showModal: {},
    userAuthenticationComplete: {},
  };

  deserializeCollection({
    additionalProperties = {},
    collection,
    modelName,
  }: ParamsForDeserializeCollection<AvailableModel>) {
    const Klass = Model[modelName];

    return collection.map((serialized) => {
      const properties = { ...serialized, ...additionalProperties } as any;
      return new Klass(properties, getOwner(this));
    });
  }

  processPayload(
    cockpitData: CockpitData,
    contractsData: ContractsData,
    recommendations: Optimization[],
  ) {
    const { categories, offers, opportunities } = cockpitData;

    const inquiries = contractsData.inquiries ?? [];
    const products = contractsData.contracts ?? [];

    const productsDeserialized = this.deserializeCollection({
      additionalProperties: {
        categories,
        offers,
      },
      collection: products as unknown as AvailableModel[],
      modelName: 'product',
    }) as unknown as ProductType[];

    productsDeserialized.map((product: ProductType) => {
      // @ts-expect-error TS(2339) FIXME: Property 'total_evaluation' does not exist on type... Remove this comment to see the full error message
      product.total_evaluation =
        product.sold_by === ProductSoldBy.US
          ? 'Sehr Gut'
          : // @ts-expect-error TS(2339) FIXME: Property 'total_evaluation' does not exist on type... Remove this comment to see the full error message
            product.total_evaluation;
      return product;
    });

    // Ember v4 Fix:
    // these collections are feed into models. that have `@computed` properties.
    // In ember v4 these computed properties are no longer writable.
    // Yet, this technique was made use of for the mokcing these objects in
    // testing, e.g. the `offer` property for the `opportunity`, instead of
    // mokcing the `offers` array and let `@computed` find the proper `offer`.
    //
    // The actual fix is to remove the `@computed` on the model class and
    // replace them with `@tracked` and at the same time, update this
    // `processPayload` to hack these "on-the-object" mocks. By extracting the
    // `.offer` object, move it to the `offers` collection (if it doesn't exist)
    // and let auto-tracking do its job on the model class, to take it from the
    // `offers` array
    //
    // this ain't be cool, but revamping these models is part of the
    // archtiecture mission
    const opportunitiesCollection = opportunities.map((opp) => {
      return Object.fromEntries(
        Object.entries(opp).filter(([key]) => key !== 'offer'),
      );
    });

    const extractedOffers = opportunities
      .map((opp) => opp.offer)
      .filter((o) => o !== undefined)
      .filter((offer) => offers.every((o) => o.id !== offer.id)); // offer is not already part of `offers`
    const offersForOpportunities = [...offers, ...extractedOffers];

    const opportunitiesDeserialized = this.deserializeCollection({
      additionalProperties: {
        offers: offersForOpportunities,
      },
      collection: opportunitiesCollection as unknown as AvailableModel[],
      modelName: 'opportunity',
    });

    const recommendationsDeserialized = this.deserializeCollection({
      additionalProperties: {
        opportunities,
        products,
      },
      collection: recommendations as unknown as AvailableModel[],
      modelName: 'recommendation',
    });

    const inquiriesDeserialized = this.deserializeCollection({
      additionalProperties: {
        categories,
        willWait: this.localStorage.getData('will_wait_for_inquiry'),
      },
      // @ts-expect-error TS(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      collection: flattenInquiries(inquiries),
      modelName: 'inquiry',
    });

    return {
      inquiries: inquiriesDeserialized,
      opportunities: opportunitiesDeserialized,
      products: productsDeserialized,
      recommendations: recommendationsDeserialized,
    };
  }

  taskForCockpit = dropTask(async () => {
    const [cockpitData, contractsData, recommendationsResponse] =
      await Promise.all([
        this.api.get('manager'),
        this.api.get(`contracts/.customer_contracts?contract_type=''`),
        this.api.get('recommendations/customer_recommendations'),
      ]);

    const recommendations =
      //@ts-expect-error "recommendations" is not a property of the response
      (recommendationsResponse && recommendationsResponse.recommendations) ??
      [];

    try {
      // run the cockpit data through the retirement cockpit lifter
      // @ts-expect-error TS(2339) FIXME: Property 'retirement_cockpit' does not exist on ty... Remove this comment to see the full error message
      cockpitData.retirement_cockpit = cockpitData.retirement_cockpit ?? {};

      return {
        // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        ...cockpitData,
        // @ts-expect-error TS(2345) FIXME: Argument of type 'Response' is not assignable to p... Remove this comment to see the full error message
        ...this.processPayload(cockpitData, contractsData, recommendations),
      };
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          message: 'Failed to process cockpit data',
          component: 'dashboard',
        });

        Sentry.captureException(error);
      });

      this.router.transitionTo('errors', {
        queryParams: { code: ErrorCode.VERTRAG_DETAILS },
      });
    }
  });

  private async waitForSession(): Promise<void> {
    if (this.session.load.isRunning) {
      await this.session.load.last;
    } else if (!this.session.isAuthenticated && !this.session.load.last) {
      await this.session.load.perform();
    }
  }

  private get hasPendingMandateStep(): boolean {
    return Boolean(emptyState(this.session.currentUser));
  }

  async shouldRedirectToDCPrimer() {
    await this.waitForSession();

    if (!this.session.currentUser) {
      return false;
    }

    if (this.hasPendingMandateStep) {
      return false;
    }

    if (
      // @ts-expect-error: Property 'shouldShowAppDownloadNudgeModal' does not exist on type 'Service'
      this.demandcheckUser.shouldRedirectToDemandcheckPrimer(
        this.session.currentUser,
        true,
      )
    ) {
      return true;
    }

    return false;
  }

  async beforeModel() {
    if (await this.shouldRedirectToDCPrimer()) {
      // @ts-expect-error: Property 'recordRedirectionToDemandcheckPrimer' does not exist on type 'Service'
      this.demandcheckUser.recordRedirectionToDemandcheckPrimer();
      // using `transitionTo` instead of `replaceWith` to keep the back button working
      this.router.transitionTo('index.demandcheck.index.primer');
      return; // for readability
    }

    const isClark1User =
      this.experiments.getVariant('business-strategy') !== 'clark2';

    if (isClark1User) {
      return;
    }

    try {
      const eligibilityData = await this.customer.getContractViewEligibility();
      const isUserEligible = Boolean(eligibilityData.eligible);

      if (isUserEligible) {
        return;
      }

      const customerState = (await this.customer.getState()) as CustomerState;

      if (customerState === CustomerState.Prospect) {
        this.router.replaceWith('contracts.index');
      }
    } catch {
      // no need to handle this error now, assume user isn't eligible
      this.router.replaceWith('contracts.index');
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
  async model(params) {
    const { offerExpired, showModal, scrollToSection, scrollToContract } =
      params;

    this.taskForCockpit.perform().then(() => {
      if (scrollToSection) {
        this.handleSectionScrolls(scrollToSection);

        // @ts-expect-error: Property 'scrollToSection' does not exist
        this.controllerFor('index.manager.landing.contracts').scrollToSection =
          null;
      }
    });

    makeContractPlaceholdersResource(this.api, this.query).fetch();
    makeAppointmentsResource(this.api, this.query).fetch();
    makePausedOpportunitiesResource(this.api, this.query).fetch();
    makeMissedAppointmentsResource(this.api, this.query).fetch();

    return {
      offerExpiredNotification: offerExpired ? JSON.parse(offerExpired) : false,
      scrollToContract,
      showModal,
      taskForCockpit: this.taskForCockpit,
      user: this.session.currentUser,
    };
  }

  @action handleSectionScrolls(scrollToSection?: string) {
    runTask(
      this,
      () => {
        document
          .querySelector(`[data-${scrollToSection}-section]`)
          ?.scrollIntoView();
      },
      0,
    );
  }
}
