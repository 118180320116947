/* eslint-disable ember/no-actions-hash */
import { doesCustomerNeedToUpgrade } from '@clark-customer/entities';
import { currentUserToCustomer } from '@clark-customer/entities/transforms';
import type { PlatformService } from '@clark-shell/ember';
import { LifeAspect } from '@clark-utils/enums-and-types';
// @ts-expect-error TS(2307) FIXME: Cannot find module '@clarksource/client/index/ques... Remove this comment to see the full error message
import type QuestionnaireController from '@clarksource/client/index/questionnaire/index/controller';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import {
  OpportunityOrigin,
  OpportunityState,
  SourceType,
} from '@clarksource/client/services/opportunities';
import { retryOnNetworkFailure } from '@clarksource/ember-api/utils';
import { action } from '@ember/object';
import type Transition from '@ember/routing/transition';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import * as Sentry from '@sentry/browser';
import { dropTask } from 'ember-concurrency';
// eslint-disable-next-line import/no-unresolved
import { all } from 'rsvp';

interface QuestionnaireRouteQueryParams {
  goBack: string;
  id: string;
  recommendationId: string;
  source: string;
}

export default class QuestionnaireRoute extends ClarkRoute {
  @service declare api: Services['api'];
  @service declare clarkVersion: Services['clark-version'];
  @service declare customer: Services['customer'];
  @service declare features: Services['features'];
  @service declare questionnaire: Services['questionnaire'];
  @service declare router: Services['router'];
  @service declare session: Services['session'];
  @service declare opportunities: Services['opportunities'];

  @service('shell/platform') declare platform: PlatformService;

  @tracked private questionnaireIdent?: string;
  @tracked private source?: string;

  @action
  error(error: Error) {
    Sentry.captureException(error);
    this.redirectOut();
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'model' implicitly has an 'any' type.
  redirect(model, transition: Transition) {
    // clark 2 (redirect any non-mandate customer to upgrade)
    if (this.shouldUpgrade(model)) {
      this.router.replaceWith('customer.upgrade', {
        queryParams: {
          callback: this.router.urlFor(
            this.routeName,
            // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
            ...transition.to.paramNames.map((n) => transition.to.params[n]),
          ),
        },
      });
    }
  }

  get needsUpgrade(): boolean {
    const { currentUser } = this.session;

    if (!currentUser) {
      return true;
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type 'User' is not assignable to param... Remove this comment to see the full error message
    const customer = currentUserToCustomer(currentUser);
    return doesCustomerNeedToUpgrade(customer);
  }

  shouldRedirectToAppointment(source: string) {
    return [
      'recommendations/employment-income-risk/cta:click',
      'recommendations/overview/number-one-rec-card:request-offer',
    ].includes(source);
  }

  async beforeModel() {
    // Should be unnecessary, since this route requires an ID param as per the router.
    const { id, source, recommendationId } = this.paramsFor(
      this.routeName,
    ) as QuestionnaireRouteQueryParams & Record<string, unknown>;
    this.questionnaireIdent = id;
    this.source = source;

    const beforeModelProps = {
      questionnaireIdent: this.questionnaireIdent,
      source: this.source,
    };

    Sentry.setTag(
      'questionnaire_identifier',
      beforeModelProps.questionnaireIdent,
    );
    Sentry.setTag('questionnaire_source', beforeModelProps.source);

    if (!id) {
      this.redirectOut();
    }

    const [questionnaire] = await all([
      this.questionnaire.getQuestionnaire(id),
    ]);

    const isHighMarginQuestionnaire = questionnaire.margin_level === 'high';

    /**
     * Currently MM/LM questionnaires are restrcited to all self service users.
     */
    if (this.needsUpgrade && !isHighMarginQuestionnaire) {
      this.router.replaceWith('customer.upgrade');
    }

    if (
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      recommendationId === undefined ||
      !this.shouldRedirectToAppointment(source)
    ) {
      return;
    }

    const source_id = Number.parseInt(recommendationId, 10);

    const opportunity = await this.opportunities.createOpportunity({
      source_type: SourceType.RECOMMENDATION,
      source_id,
      category_ident: questionnaire.category_ident,
      state: OpportunityState.CREATED,
      metadata: {
        originated_from: OpportunityOrigin.OPTIMIZATION,
      },
    });

    this.router.replaceWith('customer.appointment', {
      queryParams: {
        questionnaireIdent: this.questionnaireIdent,
        questionnaireSource: source,
        opportunityId: opportunity.id,
      },
    });
  }

  async model(
    parameters: QuestionnaireRouteQueryParams & Record<string, unknown>,
    transition: Transition,
  ) {
    try {
      let category = null;

      if (!parameters.source) {
        Sentry.captureException(
          `Questionnaire without source query param called from route: ${transition.from?.name}`,
        );
      }

      const [user, customerState, questionnaire] = await all([
        this.modelFor('index'),
        this.customer.getState(),
        this.questionnaire.getQuestionnaire(parameters.id),
      ]);

      if (questionnaire.category_ident) {
        if (
          // @ts-expect-error TS(2339) FIXME: Property 'ident' does not exist on type 'Response'... Remove this comment to see the full error message
          this.categoryDataTask.lastSuccessful?.value?.ident ===
          questionnaire.category_ident
        ) {
          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
          category = this.categoryDataTask.lastSuccessful.value;
        } else {
          category = await this.categoryDataTask.perform(
            questionnaire.category_ident,
          );
        }
      }

      return {
        customerState,
        isStart: true,
        questionnaire,
        user,
        category,
      };
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  deactivate(): void {
    // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
    Sentry.setTag('questionnaire_source');
    // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
    Sentry.setTag('questionnaire_identifier');
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'model' implicitly has an 'any' type.
  setupController(controller: QuestionnaireController, model) {
    super.setupController(controller, model);
    controller.setup(model.questionnaire);
  }

  redirectOut() {
    if (this.platform.isNative) {
      this.router.transitionTo('index.feed');
    } else {
      this.router.transitionTo('index.manager');
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'model' implicitly has an 'any' type.
  shouldUpgrade(model) {
    return (
      this.clarkVersion.isClark2 &&
      !this.isClark2Mandate() &&
      this.shouldUpgradeFromLM(model)
    );
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'model' implicitly has an 'any' type.
  shouldUpgradeFromLM(model) {
    if (this.features.isEnabled('BUYING_JOURNEY_LM')) {
      return false;
    }

    return this.isThings(model);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'model' implicitly has an 'any' type.
  isThings(model) {
    return model.questionnaire.life_aspect === LifeAspect.Things;
  }

  isClark2Mandate() {
    return (
      this.session.currentUser?.mandate.customer_state === 'mandate_customer'
    );
  }

  categoryDataTask = dropTask(async (ident) => {
    const response = await retryOnNetworkFailure(() =>
      this.api.get(`categories/ident/${ident}`),
    );

    return response;
  });
}
