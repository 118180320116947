import Controller from '@ember/controller';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type { QueryParams } from 'ember-link';

export default class IndexManagerLandingContractsController extends Controller {
  @service declare linkManager: Services['link-manager'];
  @service declare notificationCentre: Services['notification-centre'];
  @service declare tracking: Services['tracking'];
  @service declare zendeskMessenger: Services['zendesk-messenger'];
  @service('messenger/window')
  declare window: Services['messenger/window'];

  queryParams = [
    'openMessengerOnLoad',
    'openNotificationCentreOnLoad',
    'scrollToContract',
    'scrollToSection',
    'showModal',
  ];

  @tracked openMessengerOnLoad = false;
  @tracked openNotificationCentreOnLoad = null;
  @tracked scrollToContract = null;
  @tracked scrollToSection = null;
  @tracked showModal = null;

  @action openNotificationCentre(): void {
    this.tracking.track('communication/notification-centre:open');
    this.notificationCentre.show();
    this.openNotificationCentreOnLoad = null;
  }

  @action async openMessenger(): Promise<void> {
    // reset query param to avoid reopening the messenger on every route change
    this.openMessengerOnLoad = false;
    await this.zendeskMessenger.initialiseMessenger();
    // eslint-disable-next-line ember/no-runloop
    later(() => {
      if (this.zendeskMessenger.featureEnabled) {
        this.zendeskMessenger.show();
        return;
      }
      this.window.show();
    }, 0);
  }

  @action
  buildAddContractLink(query = {}) {
    return this.linkManager.createLink({
      route: 'contracts.create',
      query,
    });
  }

  @action
  buildCustomerRegistrationLink(query: QueryParams) {
    return this.linkManager.createLink({
      route: 'customer.registration',
      query,
    });
  }

  @action
  buildCustomerUpgradeLink(query: QueryParams) {
    return this.linkManager.createLink({
      route: 'customer.upgrade',
      query,
    });
  }

  @action clearInstantDocUploadQueryParams() {
    this.scrollToContract = null;
    this.showModal = null;
  }
}
