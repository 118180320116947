export default {
  "screen": "_screen_1l27yk",
  "padding-top-none": "_padding-top-none_1l27yk",
  "wrapper": "_wrapper_1l27yk",
  "headline": "_headline_1l27yk",
  "container": "_container_1l27yk",
  "list-headline": "_list-headline_1l27yk",
  "list-title": "_list-title_1l27yk",
  "list-item-icon": "_list-item-icon_1l27yk",
  "list-item-text": "_list-item-text_1l27yk",
  "list-item-heading": "_list-item-heading_1l27yk",
  "list-item-body": "_list-item-body_1l27yk",
  "button-wrapper": "_button-wrapper_1l27yk",
  "next-button": "_next-button_1l27yk",
  "mobile-legal-links": "_mobile-legal-links_1l27yk"
};
