import type ClarkUserAgentService from '@clarksource/client/services/clark-user-agent';
import type ApplicationInstance from '@ember/application/instance';

const inputtableElements = new Set(['INPUT', 'TEXTAREA']);

function repaint() {
  // Reading `offsetWidth` property on element triggers a browser repaint
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  document.documentElement.offsetWidth;
}

function handleFocusOut(event: FocusEvent) {
  // Ignore all non-inputtable elements
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!inputtableElements.has((event.target as HTMLElement)?.tagName)) {
    return;
  }

  setTimeout(() => repaint(), 0);
}

/**
 * iOS has a bug, that when the browser chrome UI is shifted around (e.g. when
 * the on-screen-keyboard is popping up), the interactive elements aren't
 * shifted to their new position and the interactive, but invisible layers stay
 * where they are and aren't interactive.
 * The fix against is to scroll the browser a little, which will re-trigger
 * another re-composition of the browser and interactive elements are back where
 * they are.
 *
 * @param app
 */
export function initialize(app: ApplicationInstance) {
  const clarkUserAgent = app.lookup(
    'service:clark-user-agent',
  ) as ClarkUserAgentService;

  if (!clarkUserAgent.isIOSApp) {
    return;
  }

  /**
   * When the keyboard is opened the screen doesnt resize, it shifts upward
   * which makes viewport loose the touch points
   * @see https://blog.opendigerati.com/the-eccentric-ways-of-ios-safari-with-the-keyboard-b5aa3f34228d
   */
  window.addEventListener('focusout', handleFocusOut, false);
}

export default { initialize };
