import type { User } from '@clark-utils/enums-and-types';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { MandateStep } from '@clarksource/client/services/mandate-state';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class MandateConfirmingRoute extends ClarkRoute {
  @service declare mandateState: Services['mandate-state'];
  @service declare router: RouterService;

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    const user = this.modelFor('index') as User;

    this.mandateState.gotoMissingStep(user, MandateStep.Confirming);

    // check if signature step is already complete, if so skip to the next step
    if (this.mandateState.isStepComplete(MandateStep.Confirming, user)) {
      const nextRoute = this.mandateState.nextStepRoute(user);
      this.router.transitionTo(nextRoute);
    }
  }

  async model() {
    return {
      user: this.modelFor('index'),
      customFunnelParams: this.modelFor('index.mandate.index'),
    };
  }

  @action
  didTransition() {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    super.actions.didTransition.call(this, false);
  }
}
