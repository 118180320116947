import type { Mandate, User } from '@clark-utils/enums-and-types';
import { Partner, WizardStep } from '@clark-utils/enums-and-types';
import Controller from '@ember/controller';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

const networksWithoutVoucher = new Set([
  'payback',
  'man',
  'n26',
  'referral program',
]);

const partnershipsWithoutVoucher = new Set([
  Partner.bunq,
  Partner.dkb,
  Partner.finanzblick,
  Partner.malburg,
  Partner.milesAndMore,
  Partner.payback,
]);

export default class MandateIndexProfilingController extends Controller {
  @service declare session: Services['session'];
  @service declare experiments: Services['experiments'];

  @service('mandate/fde-onboarding')
  declare fdeOnboarding: Services['mandate/fde-onboarding'];

  get mandate(): Mandate {
    // @ts-expect-error: Property 'mandate' does not exist on type 'unknown'
    const { mandate } = this.model;

    if (this.isRedirectedFromMandate && this.fdeOnboarding.profile) {
      const {
        birthdate,
        city,
        first_name,
        gender,
        house_number,
        last_name,
        street,
        zipcode,
      } = this.fdeOnboarding.profile;
      Object.assign(mandate, {
        birthdate,
        city,
        first_name,
        gender,
        house_number,
        last_name,
        street,
        zipcode,
      });
    }

    return mandate;
  }

  get showShortProfileForm(): boolean {
    /**
     * Do not show the short profile form for mandate confirmed users
     */
    if (this.doneConfirming && this.doneProfiling) {
      return false;
    }

    /** if current user or mandate is not available, revert to regular form as
     * user doesn't have the required fields */
    if (!this.session.currentUser || !this.session.currentMandate) {
      return false;
    }

    const { currentUser: user, currentMandate: mandate } = this.session;

    /**
     * Do not show the short profile form if email, first name or last name are not
     * already saved
     */
    if (!user.email || !mandate.first_name || !mandate.last_name) {
      return false;
    }

    return true;
  }

  get user(): User {
    // @ts-expect-error: Property 'user' does not exist on type 'unknown'
    const { user } = this.model;

    if (this.isRedirectedFromMandate && this.fdeOnboarding.profile) {
      const { email } = this.fdeOnboarding.profile;
      Object.assign(user, { email });
    }

    return user;
  }

  get isAlreadyPartOfIncentiveInfoMandateFunnelExperiment(): boolean {
    return (
      this.experiments.hasVariant('2024Q3IncentiveInfoMandateFunnel') &&
      this.experiments.getVariant('2024Q3IncentiveInfoMandateFunnel') === 'v1'
    );
  }

  get isRedirectedFromMandate(): boolean {
    // @ts-expect-error: Property 'origin' does not exist on type 'unknown'
    const { origin } = this.model;

    return origin?.startsWith('index.mandate');
  }

  get doneConfirming(): boolean {
    return this.mandate.info.wizard_steps.includes(WizardStep.confirming);
  }

  get doneProfiling(): boolean {
    return this.mandate.info.wizard_steps.includes(WizardStep.profiling);
  }

  get isVoucherHidden(): boolean {
    if (this.doneConfirming) {
      return true;
    }

    if (this.mandate.info.cash_incentive) {
      return true;
    }

    if (
      this.mandate.codeless_campaign &&
      this.isAlreadyPartOfIncentiveInfoMandateFunnelExperiment
    ) {
      return true;
    }

    const mandate = this.session.currentMandate;
    if (mandate && mandate.voucher_code !== '') {
      return true;
    }

    return (
      // @ts-expect-error TS(2345) FIXME: Argument of type 'Partner' is not assignable to p... Remove this comment to see the full error message
      partnershipsWithoutVoucher.has(this.mandate.partner as Partner) ||
      // @ts-expect-error TS(4111) FIXME: Property 'network' comes from an index signature, ... Remove this comment to see the full error message
      networksWithoutVoucher.has(this.user.adjust?.network as string)
    );
  }
}
