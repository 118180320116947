import type { Company, User } from '@clark-utils/enums-and-types';
import { WizardStep } from '@clark-utils/enums-and-types';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import { MandateStep } from '@clarksource/client/services/mandate-state';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import * as Sentry from '@sentry/browser';

export default class TargetingRoute extends ClarkRoute {
  @service declare api: Services['api'];
  @service declare category: Services['category'];
  @service declare mandateState: Services['mandate-state'];
  @service declare router: Services['router'];

  @service('mandate/payback') declare payback: Services['mandate/payback'];
  @service('mandate/fde-onboarding')
  declare fdeOnboarding: Services['mandate/fde-onboarding'];

  queryParams = {
    userAuthenticationComplete: {},
  };

  async beforeModel() {
    const user = this.modelFor('index') as User;
    this.mandateState.gotoMissingStep(user, MandateStep.Targeting);

    if (await this.doesFDEUserHaveContracts()) {
      this.mandateState.completeMandateStep(MandateStep.Targeting);
      user.mandate.info.wizard_steps.push(WizardStep.targeting);
      this.mandateState.completeAndNavigateToNextStep(
        MandateStep.Targeting,
        user,
      );
    }
  }

  async model() {
    const categories = await this.getCategories();
    const companies = await this.getCompanies();

    const paybackData = await this.payback.getUserPayback.perform();

    return {
      categories,
      companies,
      customFunnelParams: this.modelFor('index.mandate.index'),
      payback: paybackData,
      user: this.modelFor('index'),
    };
  }

  async doesFDEUserHaveContracts(): Promise<boolean> {
    const { customFunnelParams, isTCConfirmed } = this.fdeOnboarding;
    if (customFunnelParams.partner !== 'fde' || !isTCConfirmed) {
      return false;
    }
    const contracts = await this.fdeOnboarding.migrateContracts();
    return contracts.length > 0;
  }

  @action
  didTransition() {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    super.actions.didTransition.call(this, false);
  }

  private async getCategories() {
    try {
      return await this.category
        .activeCategories()
        .then((response) => response.categories);
    } catch (error) {
      this.logError(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        error,
        'Could not get any active categories from the API - Category select',
      );

      this.router.transitionTo('errors');

      return [];
    }
  }

  private async getCompanies() {
    try {
      const { companies } = await this.api.get<{ companies: Company[] }>(
        'companies',
      );

      return companies;
    } catch (error) {
      this.logError(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        error,
        'Could not get the companies from the API - Category select',
      );

      this.router.transitionTo('errors');

      return [];
    }
  }

  private logError(error: string, sentryMessage: string) {
    Sentry.withScope((scope) => {
      scope.setExtras({
        message: sentryMessage,
      });

      Sentry.captureException(error);
    });
  }
}
