import type { PlatformService } from '@clark-shell/ember';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { later } from '@ember/runloop';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { isTesting, macroCondition } from '@embroider/macros';
import * as Sentry from '@sentry/browser';

const DELAY_DURATION = macroCondition(isTesting()) ? 1 : 5000;

export default class MessengerRoute extends Route {
  @service declare intl: Services['intl'];
  @service declare mandateState: Services['mandate-state'];
  @service('messenger/window')
  declare messengerWindow: Services['messenger/window'];
  @service declare notificationBanners: Services['notification-banners'];
  @service declare router: RouterService;
  @service('shell/platform') declare platform: PlatformService;
  @service declare session: Services['session'];
  @service declare zendeskMessenger: Services['zendesk-messenger'];

  private async waitForSession(): Promise<void> {
    if (this.session.load.isRunning) {
      await this.session.load.last;
    } else if (!this.session.isAuthenticated && !this.session.load.last) {
      await this.session.load.perform();
    }
  }

  async beforeModel() {
    await this.waitForSession();

    try {
      await this.zendeskMessenger.initialiseMessenger();
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          message: 'Failed to initialise Zendesk Messenger.',
          component: 'index/messenger',
        });

        Sentry.captureException(error);
      });
    }

    const { currentUser } = this.session;

    if (currentUser?.lead) {
      /**
       * Since the user has not completed the signup process, take them to the next
       * step in the mandate funnel
       */

      const banner = this.notificationBanners.show(
        this.intl.t('communication.messenger.complete-signup'),
        {
          appearance: 'warning',
          closable: false,
        },
      );

      // eslint-disable-next-line ember/no-runloop
      later(() => {
        banner.hide();
      }, DELAY_DURATION);

      this.router.replaceWith(this.mandateState.nextStepRoute());
      return;
    }

    if (this.platform.isWeb) {
      this.router.transitionTo('index.manager');
      return;
    }

    if (this.zendeskMessenger.featureEnabled) {
      this.zendeskMessenger.show();
      return;
    }

    this.messengerWindow.show();
  }
}
