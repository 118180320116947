import { CustomerState } from '@clark-customer/entities';
import type { PlatformService } from '@clark-shell/ember';
import type { Lock } from '@clark-ui/navigation/services/navigation/confirm-transition';
import {
  CookieBannerConsentCategory,
  type Questionnaire,
} from '@clark-utils/enums-and-types';
import type { QuestionnaireCensusQuestionnaireProperties } from '@clark-utils/tracking.js';
import {
  CONSENT_COOKIE_NAME,
  type ConsentCookie,
  type ConsentCookieCategory,
  getCookieValueAsJson,
} from '@clarksource/client/utils/cookie';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type { RouteInfoWithAttributes } from '@ember/routing/route-info';
import type Transition from '@ember/routing/transition';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import type { Link } from 'ember-link';

// Importing the FieldInfo type from '@census/ember-ui' caused
// TypeScript to error in two seemingly unrelated packages.
type FieldInfo = unknown;

// Routes that can be transitioned to without restrictions (bounce screen)
// Removed 'index.manager.landing.contracts', because of the issue: JCLARK-91009
const allowedRoutesForTransitionTo = ['index.demandcheck.index.primer'];

// WARNING: Because of broccoli-asset-rev's wonky file path string detection,
// this file path MUST be a stand-alone uninterpolated string.
const widgetURL = '/@clark-questionnaire/ui/finanzen-widget.html';

export default class QuestionnaireController extends Controller {
  @service declare config: Services['config'];
  @service declare linkManager: Services['link-manager'];
  @service declare router: Services['router'];
  @service declare session: Services['session'];
  @service declare tracking: Services['tracking'];
  @service('shell/platform') declare platform: PlatformService;

  queryParams = ['source', 'goBack', 'contractId', 'recommendationId'];

  source?: string;
  goBack?: string;
  contractId?: string;
  recommendationId?: string;

  private started = false;
  private finished = false;
  private questionnaire!: Questionnaire;
  private currentQuestionToAnswer!: FieldInfo;

  setup(questionnaire: Questionnaire) {
    this.questionnaire = questionnaire;
    this.started = false;
    this.finished = false;
  }

  get isMobileView() {
    return this.platform.isMobile;
  }

  get finanzenWidgetSrc(): string {
    // @ts-expect-error: Property 'questionnaire' does not exist on type 'unknown'
    const { questionnaire } = this.model;

    const slotId = this.config.getConfig(
      'questionnaires.finanzenWidgetFlow.slotId',
    );
    const accountId = this.config.getConfig(
      'questionnaires.finanzenWidgetFlow.mrMoneyAccountId',
    );

    const queryParams = [
      `slotId=${slotId}`,
      `accountId=${accountId}`,
      `categoryIdent=${questionnaire.category_ident}`,
      `mandateId=${this.session.currentMandate?.id}`,
      `trackingConsent=${this.trackingConsent}`,
    ].join('&');

    return `${widgetURL}?${queryParams}`;
  }

  get trackingConsent(): boolean {
    const consentValue = getCookieValueAsJson(
      document.cookie,
      CONSENT_COOKIE_NAME,
    ) as ConsentCookie | undefined;
    if (!consentValue) {
      return false;
    }

    const trackingMarketingConsent: ConsentCookieCategory | undefined =
      consentValue.categories.find(
        (category) =>
          category.identifier ===
          CookieBannerConsentCategory.TRACKING_AND_MARKETING,
      );

    return trackingMarketingConsent?.enabled ?? false;
  }

  get linkForBackButton(): Link | boolean {
    const { goBack } = this;

    if (goBack) {
      return this.linkManager.createLink({
        route: goBack,
      });
    }

    // @ts-expect-error: Object is of type 'unknown'
    if (this.model.customerState === CustomerState.Prospect) {
      return false;
    }

    return true;
  }

  get censusQuestionnaireEventInfo(): QuestionnaireCensusQuestionnaireProperties {
    const {
      category_id: categoryId,
      category_ident: categoryIdent,
      category_name_hyphenated: categoryName,
      identifier: questionnaireIdent,
      margin_level: marginLevel,
      // @ts-expect-error: Object is of type 'unknown'
    } = this.model.questionnaire;

    return {
      categoryId,
      categoryIdent,
      categoryName,
      censusTemplateId: this.questionnaire.census_template_id!,
      marginLevel,
      questionnaireIdent,
    };
  }

  @action
  startQuestionnaire() {
    this.started = true;
  }

  @action
  finishQuestionnaire() {
    this.finished = true;
  }

  @action
  showBounceScreen(transition: Transition) {
    const questionnaireIsRunning = this.started && !this.finished;

    const transitionIsNotThisRoute =
      transition.to?.name !== this.router.currentRouteName;

    const skippingOptionalAppointment =
      this.questionnaire.optional_appointment &&
      allowedRoutesForTransitionTo.includes(transition.to?.name as string);

    const isNewAppointmentRoute =
      this.questionnaire.optional_appointment &&
      transition.to?.name === 'customer.appointment';

    const isAppointmentCallBackRoute =
      this.questionnaire.optional_appointment &&
      transition.to?.name === 'customer.call-back';

    // we will show the bounce screen when ...
    return (
      questionnaireIsRunning &&
      transitionIsNotThisRoute &&
      !skippingOptionalAppointment &&
      !isNewAppointmentRoute &&
      !isAppointmentCallBackRoute
    );
  }

  @action
  leaveQuestionnaire(
    lock: Lock,
    bounceResponse: { bounceReason: string; other: string },
  ): void {
    let targetRoute = '';

    if (lock.currentTransition) {
      const { name, params, paramNames } = lock.currentTransition
        .to as RouteInfoWithAttributes;

      targetRoute =
        paramNames.length > 0
          ? this.router.urlFor(name, params)
          : this.router.urlFor(name);
    }

    this.tracking.track('census/questionnaire/bounce:leave-questionnaire', {
      ...this.censusQuestionnaireEventInfo,
      // @ts-expect-error: Object is of type 'unknown'
      questionId: this.currentQuestionToAnswer.id,
      // @ts-expect-error: Object is of type 'unknown'
      questionLabel: this.currentQuestionToAnswer.label as string,
      reason:
        bounceResponse.bounceReason === 'others'
          ? bounceResponse.other
          : bounceResponse.bounceReason,
      targetRoute,
    });

    lock.allow();
  }

  @action
  handleFinanzenWidgetSubmit() {
    this.router.transitionTo('index.manager');
  }
}
